/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from 'react'
import AppRoutes from './AppRoutes'
// import { Layout } from './components/Layout'
import './custom.css'
import DashboardLayoutBasic from './components/dashboard/DashboardLayoutBasic'
import ReactGA from 'react-ga4'
import RouteChangeTracker from './components/RouteChangeTracker'
import { useAuth } from './contexts/AuthContext.jsx'
import useIdle from './hooks/useIdleTimeout'

const App = () => {
  ReactGA.initialize('G-WSQHC5C2E3')
  const [userRole, setUserRole] = useState(null)
  const { user } = useAuth()
  // eslint-disable-next-line no-unused-vars
  const { idleTimer } = useIdle({ idleTime: 5 })

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user?.roles[0]) {
        setUserRole(user?.roles[0])
        ReactGA.set({
          user_id: user?.userId,
          user_role: user?.roles[0]
        })
      }
    }
    fetchUserRole()
  }, [user])

  return (
    <DashboardLayoutBasic>
      <RouteChangeTracker />
      <AppRoutes userRole={userRole} />
    </DashboardLayoutBasic>
  )
}

export default App
