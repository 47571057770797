/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { createRoot } from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-notifications-component/dist/theme.css'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { LoaderProvider } from './contexts/LoaderContext'
import { ReactNotifications } from 'react-notifications-component'
import { LocalStorageProvider } from './contexts/LocalStorageContext'
import { PrivacyModeProvider } from './contexts/PrivacyModeContext'
import { AuthProvider } from './contexts/AuthContext'

const baseElement = document.getElementsByTagName('base')[0]
const baseUrl = baseElement ? baseElement.getAttribute('href') : '/'
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <LoaderProvider>
    <LocalStorageProvider>
      <AuthProvider>
        <BrowserRouter basename={baseUrl}>
          <PrivacyModeProvider >
            <ReactNotifications />
            <App />
          </PrivacyModeProvider >
        </BrowserRouter>
      </AuthProvider>
    </LocalStorageProvider>
  </LoaderProvider>
)
