import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { pingAuth } from '../utils/authUtils.jsx'
import { useAuth } from '../contexts/AuthContext.jsx'

const useCheckSession = (requiresAuth) => {
  const navigate = useNavigate()
  const { logout } = useAuth()

  useEffect(() => {
    if (!requiresAuth) {
      return // If no authentication is required, skip the session check
    }
    const checkSession = async () => {
      try {
        console.log('Checking session...')
        const user = await pingAuth()
        console.log('Session result:', user)
        if (!user) {
          console.log('No user found. Logging out.')
          await logout()
          navigate('/Login', { replace: true })
        }
      } catch (error) {
        console.error('Error during session check:', error)
        await logout()
        navigate('/Login', { replace: true })
      }
    }

    // Check session on load
    checkSession()

    const handleVisibilityChange = () => {
      console.log('Visibility changed:', document.hidden)
      if (!document.hidden) {
        checkSession()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [navigate])
}

export default useCheckSession
